import React from "react";
import './App.css';
import 'boxicons/css/boxicons.min.css';
import foto from "./david.jpg"

function App() {
    React.useEffect(() => {
        const menuIcon = document.querySelector("#menu-icon");
        const navbar=document.querySelector(".navbar")

        menuIcon.onclick=()=>{
            menuIcon.classList.toggle("bg-x");
            navbar.classList.toggle("active");
        }
        // Hacer algo con meuIcon si es necesario
    }, []);
  return (
      <html>
      <body>
      <header>
          <a href="#home" class="logo">David <span>Gordián</span></a>
          <i class="bx bx-menu" id="menu-icon"></i>
          <nav class="navbar">
              <a href="#home">Home </a>
              <a href="#about">About </a>
              <a href="#projects">Projects </a>
              <a href="#contact">Contact </a>
          </nav>
      </header>
      <section class="home" id="home">
          <div class="home-content">

              <h1>Hi, it´s <span>David</span></h1>
              <h3>I´m a <span>Student developer</span></h3>
              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit morbi orci dignissim, l
                  ectus vehicula rutrum molestie eget vivamus condimentum dictum facilisi vestibulum,
                  nibh purus rhoncus ac hendrerit ridiculus.</p>
              <div class="social-icons">
                  <a href="#">
                      <i class="bx bxl-github"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/gordi%C3%A1n-s%C3%A1nchez-david-118680221/">
                      <i class="bx bxl-linkedin-square"></i>
                  </a>
                  <a href="https://t.me/DavidGordian">
                      <i class="bx bxl-telegram"></i>
                  </a>
                  <a href="https://twitter.com/SancheGordian">
                      <i class="bx bxl-twitter"></i>
                  </a>
              </div>
              <div class="btn-group">
                  <a href="#" class="btn">Hire</a>
                  <a href="#contact" class="btn">contact</a>
              </div>
          </div>
          <div class="home-img">
              <img src={foto} alt=""></img>
          </div>
      </section>

      <section class="about" id="about">
          <div class="about-img">
              <img src={foto}></img>
          </div>
          <div class="about-content">
              <h2>About <span>Me</span></h2>
              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit morbi orci dignissim, l
                  ectus vehicula rutrum molestie eget vivamus condimentum dictum facilisi vestibulum,
                  nibh purus rhoncus ac hendrerit ridiculus</p>
              <a href="#" class="btn">Read More</a>
          </div>
      </section>
      <section class="projects" id="projects">
          <h2 class="heading">Projects</h2>

      </section>
      <section class="contact" id="contact">
          <h2 class="heading"> Contact <span>Me</span></h2>
          <form action="">
              <div class="input-group">
                  <div class="input-box">
                      <input type="text" placeholder="Full Name"></input>
                      <input type="email" placeholder="Email"></input>
                      <input type="number" placeholder="Phone number"></input>
                      <input type="text" placeholder="Subject"></input>
                  </div>
              </div>

              <div class="input-group-2">
                  <textarea name="" id="" cols="30" rows="10" placeholder="Your Message"></textarea>
                  <input type="submit" value="Send Message" class="btn"></input>
              </div>
          </form>
      </section>
      <footer class="footer">
          <div class="social-icons">
              <a href="#">
                  <i class="bx bxl-github"></i>
              </a>
              <a href="#">
                  <i class="bx bxl-linkedin-square"></i>
              </a>
              <a href="#">
                  <i class="bx bxl-telegram"></i>
              </a>
              <a href="#">
                  <i class="bx bxl-twitter"></i>
              </a>
          </div>
          <ul class="list">
              <li>
                  <a href="#">FAQ</a>
              </li>
              <li>
                  <a href="#projects">Projects</a>
              </li>
              <li>
                  <a href="#about">About Me</a>
              </li>
              <li>
                  <a href="#contact">Contact Me</a>
              </li>
          </ul>
          <p class="copyright"> @David Gordian | All Rights Reserved</p>
      </footer>
      </body>

      </html>

  );

}


export default App;
